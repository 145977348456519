import { ChangeDetectionStrategy, Component, EventEmitter, input, model, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SimpleValueAccessor, controlProviderFor } from '@wndr/common/core/utils/value-accessor';

/** Checkbox component. */
@Component({
	selector: 'wndrc-checkbox',
	standalone: true,
	imports: [FormsModule],
	templateUrl: './checkbox.component.html',
	styleUrl: './checkbox.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [controlProviderFor(() => CheckboxComponent)],
})
export class CheckboxComponent extends SimpleValueAccessor<boolean> {

	/** Increase opacity to use this component as readonly checkbox while disabled. */
	public readonly readonly = input(false);

	/** Checked state. */
	public readonly checked = model(false);

	/** Whether should toggle checkbox by click content inside. */
	public readonly toggleOnContentClick = input(true);

	/** On changed emitter. */
	@Output()
	public readonly changed = new EventEmitter<boolean>();

	/** @inheritdoc */
	public override writeValue(value: boolean | null): void {
		super.writeValue(value);

		this.checked.set(value ?? false);
	}

	/**
		* On change handler.
		* @param value New value.
		*/
	protected onChange(value: boolean): void {
		this.controlValue = value;
		this.changed.emit(value);
	}
}
