<label class="checkbox">
	<input
		type="checkbox"
		[(ngModel)]="checked"
		(ngModelChange)="onChange($event)"
		[disabled]="disabled"
		[class.checkbox__readonly]="readonly()"
	/>
	<span
		class="body-1"
		(click)="toggleOnContentClick() ? onChange(!checked()) : undefined"
	>
		<ng-content />
	</span>
</label>
